import React from 'react';
import { Adopt } from 'react-adopt';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { date } from '../../utils';
import ReportsScreen from '../../screens/ReportsScreen';

export const FETCH_USER_GROWTH = gql`
  query FetchUserGrowth($startDate: Date!, $endDate: Date!) {
    subscriberStats(startDate: $startDate, endDate: $endDate) {
      daily {
        date
        influencerCount
        paidFanCount
        unpaidFanCount
      }
    }
  }
`;

export const FETCH_CONVERSION = gql`
  query FetchUserGrowth($startDate: Date!, $endDate: Date!) {
    subscriberStats(startDate: $startDate, endDate: $endDate) {
      daily {
        date
        paidFanCount
        unpaidFanCount
      }
    }
  }
`;

export const FETCH_TOP_USERS = gql`
  query FetchMostFollowers {
    topInfluencers(first: 8) {
      nodes {
        uuid
        username
        followerCount
      }
    }
  }
`;

function mapUserGrowth({ data, loading, error }) {
  if (loading || error) {
    return {
      userGrowthData: [],
      userGrowthLoading: loading,
      userGrowthError: error,
    };
  }

  try {
    return {
      userGrowthData: data.subscriberStats.daily.map(day => ({
        date: date.graphDisplay(day.date),
        influencers: day.influencerCount,
        paid: day.paidFanCount,
        unpaid: day.unpaidFanCount,
      })),
      userGrowthLoading: loading,
      userGrowthError: error,
    };
  } catch (mapError) {
    return {
      userGrowthData: [],
      userGrowthLoading: loading,
      userGrowthError: error,
    };
  }
}

function mapConversion({ data, loading, error }) {
  if (loading || error) {
    return {
      conversionData: [],
      conversionLoading: loading,
      conversionError: error,
    };
  }

  try {
    return {
      conversionData: data.subscriberStats.daily.map(day => ({
        date: date.graphDisplay(day.date),
        paid: day.paidFanCount,
        unpaid: day.unpaidFanCount,
      })),
      conversionLoading: loading,
      conversionError: error,
    };
  } catch (mapError) {
    return {
      conversionData: [],
      conversionLoading: loading,
      conversionError: error,
    };
  }
}

function mapTopUsers({ data, loading, error }) {
  if (loading || error) {
    return {
      topUsersData: {},
      topUsersLoading: loading,
      topUsersError: error,
    };
  }

  try {
    return {
      topUsersData: {
        followers: data.topInfluencers.nodes.map(node => ({
          uuid: node.uuid,
          username: node.username,
          value: node.followerCount,
        })),
      },
    };
  } catch (mapError) {
    return {
      topUsersData: {},
      topUsersLoading: loading,
      topUsersError: mapError,
    };
  }
}

export const ReportsScreenConnector = () => {
  /* eslint react/prop-types: 0 */
  const mapper = {
    userGrowth: ({ render }) => (
      <Query
        query={FETCH_USER_GROWTH}
        variables={{
          startDate: date.firstDay(),
          endDate: date.lastDay(),
        }}
        fetchPolicy="network-only"
      >
        {render}
      </Query>
    ),
    conversion: ({ render }) => (
      <Query
        query={FETCH_CONVERSION}
        variables={{
          startDate: date.firstDay(),
          endDate: date.lastDay(),
        }}
        fetchPolicy="network-only"
      >
        {render}
      </Query>
    ),
    topUsers: ({ render }) => (
      <Query
        query={FETCH_TOP_USERS}
        fetchPolicy="network-only"
      >
        {render}
      </Query>
    ),
  };

  return (
    <Adopt mapper={mapper}>
      {({
        userGrowth,
        conversion,
        topUsers,
      }) => {
        const mappedUserGrowth = mapUserGrowth(userGrowth);
        const mappedConversion = mapConversion(conversion);
        const mappedTopUsers = mapTopUsers(topUsers);

        return (
          <ReportsScreen
            userGrowth={{ ...mappedUserGrowth }}
            conversion={{ ...mappedConversion }}
            topUsers={{ ...mappedTopUsers }}
            onUserGrowthDateRangeUpdate={dates => userGrowth.refetch(dates)}
            onConversionDateRangeUpdate={dates => conversion.refetch(dates)}
            onTopUsersDataRangeUpdate={() => {}}
          />
        );
      }}
    </Adopt>
  );
};

export default ReportsScreenConnector;
