import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { ApolloProvider } from '@apollo/react-common';
import { Persist } from 'react-persist';
import theme from './theme';
import Routes from './routes';
import { graphql } from './config';
import { AuthContext } from './contexts/AuthContext';
import 'noty/lib/noty.css';
import 'noty/lib/themes/mint.css';
import './styles/index.css';

class App extends Component {
  state = {
    user: {},
  };

  setUser = (user, callback) => this.setState({ user }, callback);

  render() {
    const { user } = this.state;

    return (
      <MuiThemeProvider theme={theme}>
        <ApolloProvider client={graphql}>
          <AuthContext.Provider
            value={{
              user,
              setUser: this.setUser,
            }}
          >
            <Routes />
            <Persist
              name="user"
              data={this.state}
              debounce={500}
              onMount={data => this.setState(data)}
            />
          </AuthContext.Provider>
        </ApolloProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
