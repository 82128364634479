import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Input,
  InputLabel,
  Grid,
} from '@material-ui/core';

const PaymentDetailsModal = ({
  open,
  onClose,
  onPayment,
  classes,
  paid,
  paymentDetail,
}) => (
  <Dialog maxWidth="lg" open={open} onClose={onClose}>
    <DialogTitle className={classes.modalTitle}>
      {`Amount: ${paymentDetail.amount}`}
    </DialogTitle>
    <DialogContent>
      <Container>
        <Form>
          <Grid container spacing={24}>
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="influencer">Influencer</InputLabel>
                <Input
                  disabled
                  testid="influencer"
                  id="username"
                  name="influencer"
                  defaultValue={paymentDetail.username}
                  autoComplete="influencer"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="email">Email</InputLabel>
                <Input
                  disabled
                  testid="email"
                  id="email"
                  name="email"
                  defaultValue={paymentDetail.email}
                  autoComplete="email"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="streetAddress">Address</InputLabel>
                <Input
                  disabled
                  testid="streetAddress"
                  id="streetAddress"
                  name="streetAddress"
                  defaultValue={paymentDetail.streetAddress}
                  autoComplete="streetAddress"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="apartmentNumber">Apartment Number</InputLabel>
                <Input
                  disabled
                  testid="apartmentNumber"
                  id="apartmentNumber"
                  name="apartmentNumber"
                  defaultValue={paymentDetail.apartmentNumber}
                  autoComplete="apartmentNumber"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="poBox">PO Box</InputLabel>
                <Input
                  disabled
                  testid="poBox"
                  id="poBox"
                  name="poBox"
                  defaultValue={paymentDetail.poBox}
                  autoComplete="poBox"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="city">City</InputLabel>
                <Input
                  disabled
                  testid="city"
                  id="city"
                  name="city"
                  defaultValue={paymentDetail.city}
                  autoComplete="city"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="state">State</InputLabel>
                <Input
                  disabled
                  testid="state"
                  id="state"
                  name="state"
                  defaultValue={paymentDetail.state}
                  autoComplete="state"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="country">Country</InputLabel>
                <Input
                  disabled
                  testid="country"
                  id="country"
                  name="country"
                  defaultValue={paymentDetail.country}
                  autoComplete="country"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="zipCode">Zip Code</InputLabel>
                <Input
                  disabled
                  testid="zipCode"
                  id="zipCode"
                  name="zipCode"
                  defaultValue={paymentDetail.zipCode}
                  autoComplete="zipCode"
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
                <Input
                  disabled
                  testid="phoneNumber"
                  id="phoneNumber"
                  name="phoneNumber"
                  defaultValue={paymentDetail.phoneNumber}
                  autoComplete="phoneNumber"
                />
              </FormControl>
            </Grid>
            {paymentDetail.paymentDate && (
              <Grid item xs={6}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="paymentDate">Payment Date</InputLabel>
                  <Input
                    disabled
                    testid="paymentDate"
                    id="paymentDate"
                    name="paymentDate"
                    defaultValue={paymentDetail.paymentDate}
                    autoComplete="paymentDate"
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Form>
      </Container>
    </DialogContent>
    <DialogActions className={classes.modalActions}>
      <Button
        onClick={onClose}
        color="primary"
        variant={paid ? 'contained' : 'flat'}
      >
        Close
      </Button>
      {!paid && (
        <Button
          onClick={() => onPayment({ ...paymentDetail })}
          color="primary"
          variant="contained"
        >
          Pay
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

PaymentDetailsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onPayment: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  paid: PropTypes.bool,
  paymentDetail: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    amount: PropTypes.string,
    paymentDate: PropTypes.string,
    state: PropTypes.string,
    phoneNumber: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    streetAddress: PropTypes.string,
    apartmentNumber: PropTypes.string,
    poBox: PropTypes.string,
    zipCode: PropTypes.string,
  }),
};

PaymentDetailsModal.defaultProps = {
  onPayment: () => {},
  onClose: () => {},
  open: false,
  paid: false,
  paymentDetail: {
    username: '',
    email: '',
    amount: '',
    paymentDate: '',
    state: '',
    phoneNumber: '',
    country: '',
    city: '',
    streetAddress: '',
    apartmentNumber: '',
    poBox: '',
    zipCode: '',
  },
};

const Container = styled.div`
  width: 40rem;
  margin-top: 40px;
`;

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  statusText: {
    margin: '1rem 0rem',
    fontSize: '1.4rem',
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  modalActions: {
    borderTop: '1px solid #949494',
    paddingTop: '1rem',
  },
  modalTitle: {
    borderBottom: '1px solid #949494',
  },
}))(PaymentDetailsModal);
