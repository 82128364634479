import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from 'apollo-link-error';
import {
  headers,
  users,
  notify,
} from '../../utils';
import { config } from '..';

const cache = new InMemoryCache({
  dataIdFromObject: object => object.uuid || null,
});

/**
 * Adds authorization headers to each GraphQL request
 */
const authLink = setContext(() => {
  const {
    accessToken,
    client,
    uid,
  } = headers.get();

  return {
    headers: {
      /* eslint-disable no-useless-computed-key */
      ['access-token']: accessToken,
      client,
      uid,
    },
  };
});

/**
 * Error handler for GraphQL and network errors
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      notify.error({ title: 'Error:', message });
    });
  }

  if (networkError) {
    if (networkError.statusCode === 401) {
      headers.clear();

      users.remove();

      window.location.reload(true);
    } else {
      notify.error({
        title: 'Network error:',
        message: networkError.message,
      });
    }
  }
});

/**
 * Adds upload file capability
 * Place last since it's a terminating link
 */
const uploadLink = createUploadLink({
  uri: `${config.API_URL}/graphql`,
});

/**
 * Apollo GraphQL client instance
 */
const client = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    errorLink,
    uploadLink,
  ]),
  cache,
});

export default client;
