import React from 'react';
import { withRouter } from 'react-router-dom';
import { adopt } from 'react-adopt';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { date } from '../../utils';
import DashboardScreen from '../../screens/DashboardScreen';

export const FETCH_DASHBOARD_SUMMARY = gql`
  query FetchDashboardSummary {
    dashboardReportsSummary {
      pendingInfluencerRequestsCount
      unresolvedReportedHolrCount
      unresolvedReportedHolrbackCount
      unresolvedReportedUsersCount
    }
  }
`;

export const FETCH_TOP_INFLUENCERS = gql`
  query FetchTopInfluencers {
    topInfluencers(first: 5) {
      nodes {
        uuid
        username
        followerCount
      }
    }
  }
`;

export const FETCH_TOP_HOLRS = gql`
  query FetchTopHolrs {
    topHolrs(first: 5) {
      nodes {
        user {
          uuid
          username
        }
        uuid
        caption
        viewCount
      }
    }
  }
`;

export const FETCH_TOP_CATEGORIES = gql`
  query FetchTopCategories {
    topCategories(first: 5) {
      nodes {
        uuid
        name
      }
    }
  }
`;

export const FETCH_SUBSCRIBERS_STATS = gql`
  query FetchSubscriberStats($startDate: Date!, $endDate: Date!) {
    subscriberStats(startDate: $startDate, endDate: $endDate) {
      daily {
        date
        installationCount
        subscriptionCount
      }
    }
  }
`;

function mapDashboardSummary({ data, loading, error }) {
  if (loading || error) {
    return {
      dashboardSummaryData: {},
      dashboardSummaryLoading: loading,
      dashboardSummaryError: error,
    };
  }

  try {
    return {
      dashboardSummaryData: {
        influencerRequests: data.dashboardReportsSummary.pendingInfluencerRequestsCount,
        reportedHolr: data.dashboardReportsSummary.unresolvedReportedHolrCount,
        reportedHolrback: data.dashboardReportsSummary.unresolvedReportedHolrbackCount,
        reportedUsers: data.dashboardReportsSummary.unresolvedReportedUsersCount,
      },
      dashboardSummaryLoading: loading,
      dashboardSummaryError: error,
    };
  } catch (mapError) {
    return {
      dashboardSummaryData: {},
      dashboardSummaryLoading: loading,
      dashboardSummaryError: mapError,
    };
  }
}

function mapTopInfluencers({ data, loading, error }) {
  if (loading || error) {
    return {
      topInfluencersData: [],
      topInfluencersLoading: loading,
      topInfluencersError: error,
    };
  }

  try {
    return {
      topInfluencersData: data.topInfluencers.nodes.map(node => ({
        uuid: node.uuid,
        username: node.username,
        followers: node.followerCount,
      })),
      topInfluencersLoading: loading,
      topInfluencersError: error,
    };
  } catch (mapError) {
    return {
      topInfluencersData: [],
      topInfluencersLoading: loading,
      topInfluencersError: error,
    };
  }
}

function mapTopHolrs({ data, loading, error }) {
  if (loading || error) {
    return {
      topHorlsData: [],
      topHolrsLoading: loading,
      topHolrsError: error,
    };
  }

  try {
    return {
      topHolrsData: data.topHolrs.nodes.map(node => ({
        uuid: node.uuid,
        user: {
          uuid: node.user.uuid,
          username: node.user.username,
        },
        caption: node.caption,
        views: node.viewCount,
      })),
      topHolrsLoading: loading,
      topHolrsError: error,
    };
  } catch (mapError) {
    return {
      topHorlsData: [],
      topHolrsLoading: loading,
      topHolrsError: mapError,
    };
  }
}

function mapTopCategories({ data, loading, error }) {
  if (loading || error) {
    return {
      topCategoriesData: [],
      topCategoriesLoading: loading,
      topCategoriesError: error,
    };
  }

  try {
    return {
      topCategoriesData: data.topCategories.nodes.map(node => ({
        uuid: node.uuid,
        category: node.name,
      })),
      topCategoriesLoading: loading,
      topCategoriesError: error,
    };
  } catch (mapError) {
    return {
      topCategoriesData: [],
      topCategoriesLoading: loading,
      topCategoriesError: error,
    };
  }
}

function mapSubscriberStats({ data, loading, error }) {
  if (loading || error) {
    return {
      subscriberStatsData: [],
      subscriberStatsLoading: loading,
      subscriberStatsError: error,
    };
  }

  try {
    return {
      subscriberStatsData: data.subscriberStats.daily.map(day => ({
        date: date.graphDisplay(day.date),
        installations: day.installationCount,
        subscriptions: day.subscriptionCount,
      })),
      subscriberStatsLoading: loading,
      subscriberStatsError: error,
    };
  } catch (mapError) {
    return {
      subscriberStatsData: [],
      subscriberStatsLoading: loading,
      subscriberStatsError: error,
    };
  }
}

const Composed = adopt({
  /* eslint react/prop-types: 0 */
  dashboardSummary: ({ render }) => (
    <Query
      query={FETCH_DASHBOARD_SUMMARY}
      fetchPolicy="network-only"
    >
      {render}
    </Query>
  ),
  topInfluencers: ({ render }) => (
    <Query
      query={FETCH_TOP_INFLUENCERS}
      fetchPolicy="network-only"
    >
      {render}
    </Query>
  ),
  topHolrs: ({ render }) => (
    <Query
      query={FETCH_TOP_HOLRS}
      fetchPolicy="network-only"
    >
      {render}
    </Query>
  ),
  topCategories: ({ render }) => (
    <Query
      query={FETCH_TOP_CATEGORIES}
      fetchPolicy="network-only"
    >
      {render}
    </Query>
  ),
  subscriberStats: ({ render }) => (
    <Query
      query={FETCH_SUBSCRIBERS_STATS}
      variables={{
        startDate: date.firstDay(),
        endDate: date.lastDay(),
      }}
      fetchPolicy="network-only"
    >
      {render}
    </Query>
  ),
});

export const DashboardScreenConnector = ({ history }) => (
  <Composed>
    {({
      dashboardSummary,
      topInfluencers,
      topHolrs,
      topCategories,
      subscriberStats,
    }) => {
      const mappedDashboardSummaryProps = mapDashboardSummary(dashboardSummary);
      const mappedTopInfluencers = mapTopInfluencers(topInfluencers);
      const mappedTopHolrs = mapTopHolrs(topHolrs);
      const mappedTopCategories = mapTopCategories(topCategories);
      const mappedSubscriberStats = mapSubscriberStats(subscriberStats);

      return (
        <DashboardScreen
          dashboardSummary={{
            ...mappedDashboardSummaryProps,
            onInfluencerRequestsReviewClick: () => history.push('/requests'),
            onReportedHolrReviewClick: () => history.push('/reported'),
            onReportedHolrbackReviewClick: () => history.push('/reported?tab=1'),
            onReportedUsersReviewClick: () => history.push('/reported?tab=2'),
          }}
          topInfluencers={{ ...mappedTopInfluencers }}
          topHolrs={{ ...mappedTopHolrs }}
          topCategories={{ ...mappedTopCategories }}
          subscriberStats={{ ...mappedSubscriberStats }}
          onTopInfluencersClick={({ uuid }) => history.push(`/users/${uuid}`)}
          onTopHolrsClick={({ user }) => history.push(`/users/${user.uuid}`)}
          onTopCategoriesClick={({ uuid }) => history.push(`/categories/${uuid}`)}
        />
      );
    }}
  </Composed>
);

export default withRouter(DashboardScreenConnector);
