import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { lowerCase } from 'lodash';
import { withStyles } from '@material-ui/core/styles';

import {
  Paper,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from '@material-ui/core';

class UsersListTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        username: PropTypes.string,
        email: PropTypes.string,
        adminRole: PropTypes.string,
        appRole: PropTypes.string,
      }),
    ),
    loading: PropTypes.bool,
    onItemClick: PropTypes.func,
    classes: PropTypes.object,
    loadMore: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
  }

  static defaultProps = {
    data: [],
    classes: {},
    onItemClick: () => {},
    loading: false,
  }

  state = {
    page: 0,
    rowsPerPage: 10,
    rowsPageOptions: [],
  };

  handleChangePage = (e, page) => {
    const { loadMore } = this.props;
    const { rowsPerPage } = this.state;

    this.setState({ page });
    loadMore(rowsPerPage);
  }

  renderRows = () => {
    const { data, onItemClick, classes } = this.props;
    const { page, rowsPerPage } = this.state;

    return data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((user) => {
        const {
          uuid,
          fullName,
          username = '-',
          email = '-',
          adminRole = '-',
          appRole = '-',
        } = user;

        return (
          <TableRow
            hover
            onClick={() => onItemClick(uuid)}
            key={uuid}
            classes={{ hover: classes.tableRow }}
          >
            <TableCell className={classes.tableCell}>{fullName}</TableCell>
            <TableCell className={classes.tableCell}>{`@${username}`}</TableCell>
            <TableCell className={classes.tableCell}>{email}</TableCell>
            <TableCell className={classes.tableCell}>{lowerCase(appRole) || '-'}</TableCell>
            <TableCell className={classes.tableCell}>{lowerCase(adminRole) || '-'}</TableCell>
          </TableRow>
        );
      });
  }

  renderContent = () => {
    const {
      data,
      loading,
      classes,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <TableRow>
            <TableCell colSpan={6} padding="none" className={classes.loading}>
              <CircularProgress thickness={5} size={80} />
            </TableCell>
          </TableRow>
        );
      case !data.length:
        return (
          <TableRow>
            <TableCell colSpan={6} padding="none" className={classes.loading}>
              <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
            </TableCell>
          </TableRow>
        );
      default:
        return this.renderRows();
    }
  }

  render() {
    const { totalCount, classes } = this.props;
    const { page, rowsPerPage, rowsPageOptions } = this.state;
    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Name</TableCell>
              <TableCell className={classes.tableHead}>Username</TableCell>
              <TableCell className={classes.tableHead}>Email</TableCell>
              <TableCell className={classes.tableHead}>Type</TableCell>
              <TableCell className={classes.tableHead}>Admin Privileges</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderContent()}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={5}
                rowsPerPageOptions={rowsPageOptions}
                rowsPerPage={rowsPerPage}
                page={page}
                count={totalCount}
                SelectProps={{ native: true }}
                onChangePage={this.handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(theme => ({
  loading: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 10}px !important`,
  },
  tableHead: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tableCell: {
    border: 'none',
  },
  tableRow: {
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },
  },
}))(UsersListTable);
