import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ScreenContainer } from '../../components/layout';
import {
  UserGrowthChart,
  ConversionChart,
  RevenueChart,
  TopUsersChart,
} from '../../components/charts';

class ReportsScreen extends Component {
  static propTypes = {
    userGrowth: PropTypes.shape({
      userGrowthData: PropTypes.array,
      userGrowthLoading: PropTypes.bool,
    }),
    conversion: PropTypes.shape({
      conversionData: PropTypes.array,
      conversionLoading: PropTypes.bool,
    }),
    revenue: PropTypes.shape({
      revenueData: PropTypes.array,
      revenueLoading: PropTypes.bool,
    }),
    topUsers: PropTypes.shape({
      topUsersData: PropTypes.object,
      topUsersLoading: PropTypes.bool,
    }),
    onUserGrowthDateRangeUpdate: PropTypes.func,
    onConversionDateRangeUpdate: PropTypes.func,
    onRevenueDateRangeUpdate: PropTypes.func,
    onTopUsersDateRangeUpdate: PropTypes.func,
  }

  static defaultProps = {
    userGrowth: {
      userGrowthData: [],
      userGrowthLoading: false,
    },
    conversion: {
      conversionData: [],
      conversionLoading: false,
    },
    revenue: {
      revenueData: [],
      revenueLoading: false,
    },
    topUsers: {
      topUsersData: {},
      topUsersLoading: false,
    },
    onUserGrowthDateRangeUpdate: () => {},
    onConversionDateRangeUpdate: () => {},
    onRevenueDateRangeUpdate: () => {},
    onTopUsersDateRangeUpdate: () => {},
  }

  handleDownload = chart => async () => {
    const input = document.getElementById(chart);
    const canvas = await html2canvas(input);
    const img = canvas.toDataURL('image/png', 1);
    // eslint-disable-next-line
    const pdf = new jsPDF({
      orientation: 'landscape',
      unit: 'px',
      format: [800, 600],
    });
    pdf.addImage(img, 'PNG', 0, 0, 700, 200);
    pdf.save(`${chart}.pdf`);
  }

  render() {
    const {
      userGrowth: {
        userGrowthData,
        userGrowthLoading,
      },
      conversion: {
        conversionData,
        conversionLoading,
      },
      revenue: {
        revenueData,
        revenueLoading,
      },
      topUsers: {
        topUsersData,
        topUsersLoading,
      },
      onUserGrowthDateRangeUpdate,
      onConversionDateRangeUpdate,
      onRevenueDateRangeUpdate,
      onTopUsersDateRangeUpdate,
    } = this.props;

    return (
      <ScreenContainer>
        <ChartContainer>
          <UserGrowthChart
            data={userGrowthData}
            loading={userGrowthLoading}
            onDateRangeUpdate={onUserGrowthDateRangeUpdate}
            onDownloadClick={this.handleDownload('user-growth-chart')}
          />
        </ChartContainer>
        <ChartContainer>
          <ConversionChart
            data={conversionData}
            loading={conversionLoading}
            onDateRangeUpdate={onConversionDateRangeUpdate}
            onDownloadClick={this.handleDownload('conversion-chart')}
          />
        </ChartContainer>
        <ChartContainer>
          <RevenueChart
            data={revenueData}
            loading={revenueLoading}
            onDateRangeUpdate={onRevenueDateRangeUpdate}
            onDownloadClick={this.handleDownload('revenue-chart')}
          />
        </ChartContainer>
        <ChartContainer>
          <TopUsersChart
            data={topUsersData}
            loading={topUsersLoading}
            onDateRangeUpdate={onTopUsersDateRangeUpdate}
            onDownloadClick={this.handleDownload('top-users-chart')}
          />
        </ChartContainer>
      </ScreenContainer>
    );
  }
}

const ChartContainer = styled.div`
  margin: 1rem;
`;

export default ReportsScreen;
