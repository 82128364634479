export const influencerRequestsStates = [
  'PENDING',
  'ACCEPTED',
  'REJECTED',
];

export const sortOrder = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};

export const sortColumn = {
  ID: 'ID',
  CREATED_AT: 'CREATED_AT',
  UPDATED_AT: 'UPDATED_AT',
};

export default {
  influencerRequestsStates,
  sortOrder,
  sortColumn,
};
