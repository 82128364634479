/**
 * Add the query/mutation name, along with success/error message for each
 * Titles are optional, messages are required
 */
export default {
  ADD_CATEGORY: {
    success: {
      title: 'Success!',
      message: 'The category has been successfully created',
    },
    error: {
      message: 'There\'s been an error while creating the category. Please try again.',
    },
  },
  ADD_SUBCATEGORY: {
    success: {
      title: 'Success!',
      message: 'The subcategory has been successfully created',
    },
    error: {
      message: 'There\'s been an error while creating the subcategory. Please try again.',
    },
  },
  CREATE_NOTIFICATION: {
    error: {
      message: 'There\'s been an error while creating the notification. Please try again.',
    },
    success: {
      title: 'Success!',
      message: 'The notification has been successfully created',
    },
  },
  DELETE_NOTIFICATION: {
    success: {
      title: 'Success!',
      message: 'The notification has been successfully deleted',
    },
    error: {
      message: 'There\'s been an error while deleting the notification. Please try again.',
    },
  },
  DELETE_SUBCATEGORY: {
    success: {
      title: 'Success!',
      message: 'The subcategory has been successfully deleted',
    },
    error: {
      message: 'There\'s been an error while deleting the subcategory. Please try again.',
    },
  },
  FETCH_CATEGORY: {
    error: {
      message: 'There\'s been an error while fetching the category. Please try again.',
    },
  },
  FETCH_CATEGORIES: {
    error: {
      message: 'There\'s been an error while fetching the categories. Please try again.',
    },
  },
  FETCH_SUBCATEGORY: {
    error: {
      message: 'There\'s been an error while fetching the subcategory. Please try again.',
    },
  },
  FETCH_SUBCATEGORIES: {
    error: {
      message: 'There\'s been an error while fetching the subcategories. Please try again.',
    },
  },
  RESOLVE_INFLUENCER_REQUEST: {
    success: {
      title: 'Success!',
      message: 'The request has been successfully resolved',
    },
    error: {
      message: 'There\'s been an error while resolving the request. Please try again.',
    },
  },
  RESOLVE_REPORTED_PROBLEM: {
    success: {
      title: 'Success!',
      message: 'The reported problem has been successfully resolved',
    },
    error: {
      message: 'There\'s been an error while resolving the reported problem. Please try again.',
    },
  },
  RESOLVE_REPORTED_HOLR: {
    success: {
      title: 'Success!',
      message: 'The reported holr has been successfully resolved',
    },
    error: {
      message: 'There\'s been an error while resolving the reported holr. Please try again.',
    },
  },
  RESOLVE_REPORTED_USER: {
    success: {
      title: 'Success!',
      message: 'The reported user has been successfully resolved',
    },
    error: {
      message: 'There\'s been an error while resolving the reported user. Please try again.',
    },
  },
  UPDATE_CATEGORY: {
    success: {
      title: 'Success!',
      message: 'The category has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating the category. Please try again.',
    },
  },
  UPDATE_NOTIFICATION: {
    success: {
      title: 'Success!',
      message: 'The notification has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating the notification. Please try again.',
    },
  },
  UPDATE_PROFILE: {
    success: {
      title: 'Success!',
      message: 'Your profile has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating your profile. Please try again.',
    },
  },
  UPDATE_LEGAL_NOTE: {
    success: {
      title: 'Success!',
      message: 'Your legal note has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating your legal note. Please try again.',
    },
  },
  UPDATE_SUBCATEGORY: {
    success: {
      title: 'Success!',
      message: 'The subcategory has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating the subcategory. Please try again.',
    },
  },
  UPDATE_USER: {
    success: {
      title: 'Success!',
      message: 'The user has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating.',
    },
  },
  DELETE_USER: {
    success: {
      title: 'Success!',
      message: 'The user has been successfully deleted.',
    },
    error: {
      message: 'There\'s been an error while updating.',
    },
  },
  UPDATE_ADMIN_PRIVILEGES: {
    success: {
      title: 'Success!',
      message: 'The Admin Privileges has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating.',
    },
  },
  UPDATE_USER_TYPE: {
    success: {
      title: 'Success!',
      message: 'The user type has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating.',
    },
  },
  UPDATE_UNBAN_USER: {
    success: {
      title: 'Success!',
      message: 'The user has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating.',
    },
  },
  UPDATE_BAN_USER: {
    success: {
      title: 'Success!',
      message: 'The user has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating.',
    },
  },
  UPDATE_HOLR: {
    success: {
      title: 'Success!',
      message: 'The holr has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating.',
    },
  },
  UPDATE_REPORTED_PROBLEM: {
    success: {
      title: 'Success!',
      message: 'The report has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating.',
    },
  },
  UPDATE_BAN_HOLR: {
    success: {
      title: 'Success!',
      message: 'The holr has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating.',
    },
  },
  UPDATE_FLAG_BAN_HOLR: {
    success: {
      title: 'Success!',
      message: 'The holr has been successfully updated',
    },
    error: {
      message: 'There\'s been an error while updating.',
    },
  },
  CREATE_USER_PAYMENT: {
    success: {
      title: 'Success!',
      message: 'User payment has been successfully created',
    },
    error: {
      message: 'There\'s been an error while creating the payment. Please try again.',
    },
  },
};
