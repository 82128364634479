import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { ScreenContainer } from '../../components/layout';
import { SearchBox, DateRangePicker } from '../../components/forms';
import { PaymentsDueTable, SubscriptionPaymentsTable } from '../../components/tables';
import { PaymentDetailsModal } from '../../components/modals';
import { date } from '../../utils';

class PaymentsScreen extends Component {
  static propTypes = {
    subscriptions: PropTypes.shape({
      data: PropTypes.array,
      loading: PropTypes.bool,
      totalCount: PropTypes.number,
    }),
    paymentsDue: PropTypes.shape({
      data: PropTypes.array,
      loading: PropTypes.bool,
      totalCount: PropTypes.number,
    }),
    loadMore: PropTypes.shape({
      subscriptions: PropTypes.func,
      paymentsDue: PropTypes.func,
    }),
    onSearch: PropTypes.shape({
      subscriptions: PropTypes.func,
      paymentsDue: PropTypes.func,
    }),
    onDateRangeUpdate: PropTypes.func,
    onPayment: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    subscriptions: {
      data: [],
      loading: false,
      totalCount: 0,
    },
    paymentsDue: {
      data: [],
      loading: false,
      totalCount: 0,
    },
    loadMore: {
      subscriptions: () => {},
      paymentsDue: () => {},
    },
    onSearch: {
      subscriptions: () => {},
      paymentsDue: () => {},
    },
    onDateRangeUpdate: () => {},
    onPayment: () => {},
  };

  state = {
    activeTab: 0,
    startDate: new Date(date.firstDay()),
    endDate: new Date(date.lastDay()),
    detailsModal: false,
    selectedItem: {},
  };

  toggleModal = (selectedItem) => {
    const { detailsModal } = this.state;

    this.setState({
      detailsModal: !detailsModal,
      selectedItem,
    });
  }

  handleTabChange = (event, value) => this.setState({ activeTab: value });

  handleDate = (startDate, endDate) => {
    const { onDateRangeUpdate } = this.props;

    onDateRangeUpdate({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });

    this.setState({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  };

  handleSearch = (term) => {
    const { activeTab } = this.state;
    const { onSearch } = this.props;

    if (activeTab === 0) {
      onSearch.subscriptions({ term });
    }

    if (activeTab === 1) {
      onSearch.paymentsDue({ term });
    }
  };

  handlePayment = async (item) => {
    const { onPayment } = this.props;

    await onPayment(item);

    this.toggleModal();
  }

  render() {
    const {
      activeTab,
      startDate,
      endDate,
      detailsModal,
      selectedItem,
    } = this.state;

    const {
      subscriptions,
      paymentsDue,
      loadMore,
      classes,
    } = this.props;

    return (
      <ScreenContainer>
        <Grid container>
          <Grid item xs={6}>
            <Tabs
              value={activeTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Payments Made" />
              <Tab label="Payments Due" />
            </Tabs>
          </Grid>
          <Grid item xs={6} className={classes.filters}>
            <SearchBoxWrapper>
              <SearchBox
                onSearch={this.handleSearch}
                placeholder="Search influencers"
              />
            </SearchBoxWrapper>
            {activeTab === 0 && (
              <DateRangePicker
                onUpdate={this.handleDate}
                startDate={startDate}
                endDate={endDate}
                label="Date Range"
              />
            )}
          </Grid>
        </Grid>
        <TableWrapper>
          {activeTab === 0 && (
            <SubscriptionPaymentsTable
              data={subscriptions.data}
              loading={subscriptions.loading}
              onItemClick={this.toggleModal}
              loadMore={loadMore.subscriptions}
              totalCount={subscriptions.totalCount}
            />
          )}
          {activeTab === 1 && (
            <PaymentsDueTable
              data={paymentsDue.data}
              loading={paymentsDue.loading}
              onItemClick={this.toggleModal}
              loadMore={loadMore.paymentsDue}
              totalCount={paymentsDue.totalCount}
            />
          )}
        </TableWrapper>
        <PaymentDetailsModal
          open={detailsModal}
          paid={!activeTab}
          paymentDetail={selectedItem}
          onPayment={this.handlePayment}
          onClose={() => this.toggleModal()}
        />
      </ScreenContainer>
    );
  }
}

const SearchBoxWrapper = styled.div`
  margin-top: 6px;
  margin-right: 2rem;
`;

const TableWrapper = styled.div`
  margin-top: 3rem;
`;

export default withStyles({
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
})(PaymentsScreen);
