import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';

class SearchBox extends React.Component {
  static propTypes = {
    onSearch: PropTypes.func,
    fullWidth: PropTypes.bool,
    placeholder: PropTypes.string,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onSearch: () => {},
    fullWidth: false,
    placeholder: 'Search',
  };

  state = { value: '' };

  handleChange = (value) => {
    const { onSearch } = this.props;

    this.setState({ value }, () => onSearch(value.toLowerCase()));
  }

  render() {
    const {
      fullWidth,
      placeholder,
      classes,
    } = this.props;
    const { value } = this.state;

    return (
      <TextField
        fullWidth={fullWidth}
        value={value}
        onChange={e => this.handleChange(e.target.value)}
        placeholder={placeholder}
        name="search"
        InputProps={{
          className: classes.inputWrapper,
          classes: {
            input: classes.input,
          },
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    );
  }
}

export default withStyles(() => ({
  inputWrapper: { padding: '5px 0' },
  input: {
    '&::placeholder': {
      opacity: 0.54,
    },
  },
}))(SearchBox);
