import gql from 'graphql-tag';

export const FETCH_SUBSCRIPTION_PAYMENTS = gql`
  query FetchSubscriptionPayments(
    $first: Int
    $after: String
    $startDate: Date
    $endDate: Date
    $term: String
  ) {
    userPayments(
      first: $first
      after: $after
      startDate: $startDate
      endDate: $endDate
      query: $term
    ) {
      edges {
        node {
          uuid
          user {
            uuid
            username
            streetAddress
            apartmentNumber
            poBox
            city
            state
            country
            zipCode
            phoneNumber
          }
          currentUserEmail
          amount
          createdAt
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const FETCH_PAYMENTS_DUE = gql`
  query FetchPaymentsDue (
    $first: Int
    $after: String
    $term: String
  ) {
    usersWithDuePayments(
      first: $first
      after: $after
      query: $term
    ) {
      edges {
        node {
          uuid
          username
          email
          duePayment
          streetAddress
          apartmentNumber
          poBox
          city
          state
          country
          zipCode
          phoneNumber
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const CREATE_USER_PAYMENT = gql`
  mutation CreateUserPayment($uuid: ID!) {
    createUserPayment(userUuid: $uuid) {
      payment {
        uuid
        user {
          uuid
          username
          streetAddress
          apartmentNumber
          poBox
          city
          state
          country
          zipCode
          phoneNumber
        }
        currentUserEmail
        amount
        createdAt
      }
    }
  }
`;

export default {
  FETCH_SUBSCRIPTION_PAYMENTS,
  FETCH_PAYMENTS_DUE,
  CREATE_USER_PAYMENT,
};
