import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { headers, users } from '../../utils';
import withAuth from '../../contexts/AuthContext';
import auth from '../../services/auth';
import LoginScreen from '../../screens/LoginScreen';

export class LoginScreenConnector extends Component {
  static propTypes = {
    user: PropTypes.any.isRequired,
    setUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
  };

  state = {
    open: false,
    message: '',
  };

  componentDidMount = () => {
    const { history } = this.props;

    if (history.location.state) {
      this.setState({
        message: history.location.state.message,
        open: true,
      }, () => {
        setTimeout(() => this.setState({
          open: false,
        }), 3000);

        history.replace({ ...history.location, state: undefined });
      });
    }
  }

  login = async (payload) => {
    const { setUser } = this.props;

    try {
      const response = await auth.login(payload);

      if (!response.data.data.admin_role) {
        throw new Error('You don\'t have permission to access this. Please ask your administrator for assistance');
      }

      headers.store(response);

      const mappedUser = users.map(response.data.data);

      setUser(mappedUser);

      return Promise.resolve();
    } catch (error) {
      this.setState({
        open: true,
        message: error.response ? error.response.data.errors.toString() : error.toString(),
      }, () => {
        setTimeout(() => this.setState({ open: false }), 3000);
      });

      return Promise.reject(error);
    }
  }

  render() {
    const {
      user,
      history,
    } = this.props;
    const { open, message } = this.state;

    if (!isEmpty(user) && headers.check()) {
      return <Redirect to="/" />;
    }

    return (
      <LoginScreen
        formProps={{
          onSubmit: this.login,
          onForgotPasswordClick: () => history.push('password-recovery'),
        }}
        toastProps={{
          open,
          message,
        }}
      />
    );
  }
}

export default withRouter(withAuth(LoginScreenConnector));
