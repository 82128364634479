import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField,
  MenuItem,
  Paper,
  Chip,
} from '@material-ui/core';
import deburr from 'lodash/deburr';
import differenceBy from 'lodash/differenceBy';
import shortid from 'shortid';

class ChipsBox extends React.Component {
  static propTypes = {
    addNew: PropTypes.bool,
    optionsItems: PropTypes.arrayOf(PropTypes.object),
    selectedItems: PropTypes.array,
    itemLabel: PropTypes.string,
    itemKey: PropTypes.string,
    placeholder: PropTypes.string,
    onSelect: PropTypes.func,
    onRemove: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    addNew: false,
    optionsItems: [],
    selectedItems: [],
    itemLabel: '',
    itemKey: '',
    placeholder: '',
    onSelect: () => {},
    onRemove: () => {},
  };

  getItems = (value) => {
    const {
      optionsItems,
      selectedItems,
      itemLabel,
    } = this.props;

    const input = deburr(value.trim()).toLowerCase();

    const filteredOptions = differenceBy(optionsItems, selectedItems, itemLabel)
      .filter(suggestion => (
        input
          && suggestion[itemLabel].slice(0, input.length).toLowerCase() === input
          && !selectedItems.includes(suggestion)
      )).slice(0, 4);

    return filteredOptions;
  };

  renderInput = ({ InputProps, ref, ...other }) => (
    <TextField
      name="search"
      InputProps={{
        inputRef: ref,
        ...InputProps,
      }}
      {...other}
    />
  );

  renderSuggestion = ({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    clearSelection,
  }) => {
    const {
      addNew,
      onSelect,
      itemLabel,
      itemKey,
    } = this.props;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion[itemKey] || shortid.generate()}
        selected={highlightedIndex === index}
        component="div"
        onClick={() => {
          onSelect(suggestion);
          clearSelection();
        }}
      >
        {addNew
          ? `Add "${suggestion[itemLabel]}"`
          : suggestion[itemLabel]
        }
      </MenuItem>
    );
  };

  render() {
    const {
      addNew,
      onRemove,
      selectedItems,
      itemLabel,
      itemKey,
      placeholder,
      classes,
    } = this.props;

    return (
      <React.Fragment>
        <Downshift>
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            clearSelection,
            inputValue,
            isOpen,
          }) => (
            <div>
              {this.renderInput({
                fullWidth: true,
                InputProps: getInputProps({ placeholder }),
              })}
              <div {...getMenuProps()}>
                {isOpen && (
                  <Paper square className={classes.paper}>
                    {addNew && inputValue.length
                      ? this.renderSuggestion({
                        suggestion: {
                          [itemLabel]: inputValue,
                        },
                        index: 0,
                        itemProps: getItemProps({ item: inputValue }),
                        highlightedIndex,
                        clearSelection,
                      })
                      : this.getItems(inputValue).map((suggestion, index) => this.renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion[itemLabel] }),
                        highlightedIndex,
                        clearSelection,
                      }))
                    }
                  </Paper>
                )}
              </div>
            </div>
          )}
        </Downshift>
        <ChipsContainer>
          {selectedItems.map((item, index) => (
            <Chip
              key={item[itemKey] || shortid.generate()}
              label={item[itemLabel]}
              onDelete={() => onRemove(index)}
              className={classes.chips}
            />
          ))}
        </ChipsContainer>
      </React.Fragment>
    );
  }
}

const ChipsContainer = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
`;

export default withStyles({
  chips: { margin: '0px 5px 10px' },
})(ChipsBox);
