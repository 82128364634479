import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import BarShape from '../../../utils/barShape';
import appTheme from '../../../theme';

const DashboardSubscribersChart = ({
  data,
  loading,
  classes,
}) => (
  <Card>
    <CardHeader
      title="Subscribers"
      subheader={(
        <Fragment>
          <Typography variant="title" className={classes.installed}>Installed</Typography>
          <Typography variant="title" className={classes.subscribed}>Subscribed</Typography>
          <Typography variant="title" className={classes.thisMonth}>This Month</Typography>
        </Fragment>
      )}
      classes={{
        content: classes.content,
        subheader: classes.subheader,
      }}
    />
    <CardContent>
      { loading
        ? (
          <LoadingContainer>
            <CircularProgress thickness={5} size={80} />
          </LoadingContainer>
        )
        : (
          <ResponsiveContainer minWidth={200} minHeight={250}>
            <BarChart data={data}>
              <CartesianGrid />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="installations" stackId="a" fill={appTheme.palette.common.graphRed} shape={<BarShape />} />
              <Bar dataKey="subscriptions" stackId="a" fill={appTheme.palette.common.graphYellow} shape={<BarShape top />} />
            </BarChart>
          </ResponsiveContainer>
        )}
    </CardContent>
  </Card>
);

DashboardSubscribersChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      installations: PropTypes.number,
      subscriptions: PropTypes.number,
    }),
  ),
  loading: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

DashboardSubscribersChart.defaultProps = {
  data: [],
  loading: false,
};

const LoadingContainer = styled.div`
  text-align: center;
`;

export default withStyles(theme => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subheader: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  installed: {
    color: theme.palette.common.graphRed,
    fontWeight: 'bold',
  },
  subscribed: {
    color: theme.palette.common.graphYellow,
    fontWeight: 'bold',
  },
  thisMonth: {
    color: theme.palette.common.gray,
    fontWeight: 'bold',
  },
}))(DashboardSubscribersChart);
