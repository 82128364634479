import get from 'lodash/get';
import { date } from '../../utils';

export function mapSubscriptions({ data, loading, error }) {
  if (loading || error) {
    return { data: [], loading, error };
  }

  try {
    return {
      data: data.userPayments.edges.map(edge => ({
        uuid: edge.node.uuid,
        username: get(edge, 'node.user.username'),
        email: edge.node.currentUserEmail,
        amount: `$${edge.node.amount}`,
        paymentDate: date.lastUpdated(edge.node.createdAt),
        ...get(edge, 'node.user', {}),
      })),
      loading,
      error,
      totalCount: data.userPayments.totalCount,
    };
  } catch (mapError) {
    return { data: [], loading, error: mapError };
  }
}

export function mapPaymentsDue({ data, loading, error }) {
  if (loading || error) {
    return { data: [], loading, error };
  }

  try {
    return {
      data: data.usersWithDuePayments.edges.map(edge => ({
        uuid: edge.node.uuid,
        amount: `$${edge.node.duePayment}`,
        ...get(edge, 'node', {}),
      })),
      loading,
      error,
      totalCount: data.usersWithDuePayments.totalCount,
    };
  } catch (mapError) {
    return { data: [], loading, error: mapError };
  }
}

export default {
  mapSubscriptions,
  mapPaymentsDue,
};
